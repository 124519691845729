<template>
  <div v-loading="loading" id="orderHistory" style="max-width:1100px;padding-top:30px;margin:0 auto;">
    <el-divider></el-divider>
    <order-history-item style="text-align:left;" v-for="(item, index) in clientOrderList" :key="index" :item="item" @onStatusChanged="onStatusChanged"></order-history-item>
    <el-pagination background layout="prev, pager, next" :total="clientOrderCount" :page-size="pageSize" @current-change="handlePageChange"></el-pagination>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import OrderHistoryItem from './OrderHistoryItem.vue'

export default {
    name: 'orderHistory',
    components: {
      OrderHistoryItem,
    },
    props: ['status'],
    data(){
      return {
        loading: false
      }
    },
    computed: {
      ...mapState('order', ['clientOrderList', 'clientOrderCount', 'pageSize', 'pageIndex'])
    },
    watch: {
      status:function(val, oldVal){
        console.log(val, oldVal)
        this.loadOrderList()
      }
    },
    mounted(){
      this.loadOrderList()
    },
    methods: {
      ...mapActions('order', ['getClientOrderList', 'setCurrentIndex']),
      loadOrderList(){
        this.loading = true
        this.getClientOrderList({
          status: this.status
        }).then(()=>{
          this.loading = false
        }).catch(()=>{
          this.loading = false
        })
      },
      handlePageChange(val) {
        this.loading = true
        this.setCurrentIndex({
          pageIndex: val,
          status: this.status
        }).then(()=>{
          this.loading = false
        }).catch(()=>{
          this.loading = false
        })
      },
      onStatusChanged(){
        this.setCurrentIndex({
          pageIndex: this.pageIndex,
          status: this.status
        })
      }
    }
}
</script>

<style>

</style>