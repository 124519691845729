<template>
  <div></div>
</template>

<script>
export default {
    name: 'orders',
    data(){
        return {
            activeStatus: 'paid'
        }
    },
    mounted(){
        this.activeStatus = this.$route.query.status
    }
}
</script>

<style>

</style>