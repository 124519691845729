<template>
  <div v-loading="loading" id="orderHistoryItem" style="margin-top:0px;">
    <div style="display:-webkit-box;margin-bottom:10px;">
      <div style="-webkit-box-flex:1;"><i class="el-icon-goods">  {{orderName}}</i></div>
      <div style="color:#F56C6C; text-align:right;">{{orderStatus}}</div>
    </div>
    <div style="display:-webkit-box;">
      <div v-for="product in orderFirstThreeItems" :key="product.ID">
        <el-image style="width:80px;height:80px;margin-right:10px;" :src="product.product_image"></el-image>
      </div>
      <div style="-webkit-box-flex:1;line-height:80px;text-align:right;">{{item.items.length}} items</div>
    </div>
    <div style="text-align:right;">Total: ${{(item.total/100).toFixed(2)}}</div>
    <div style="text-align:right;margin-bottom:0px;display:-webkit-box">
      <div style="-webkit-box-flex:1;"></div>
      <div style="margin-right:20px;"><el-button type="text" @click="viewDetailClick">View Detail</el-button></div>
      <div>
        <el-popconfirm title="I have already received all items in this order" @confirm="confirmDeliverClick">
          <el-button :disabled="orderStatus == 'delivered'" slot="reference" type="text">Confirm Deliver</el-button>
        </el-popconfirm>
      </div>
    </div>
    <el-divider></el-divider>
  </div>
</template>

<script>
import orderApi from '../../api/order'
import { mapState } from 'vuex'

export default {
    name: 'orderHistoryItem',
    props: ['item'],
    data(){
      return {
        loading: false,
      }
    },
    computed: {
      ...mapState('client', ['token']),
      orderName(){
        if(this.item == null || this.item.items.length == 0) return 'NO Items'
        return this.item.items[0].product_name + '...' 
      },
      orderFirstThreeItems(){
        if(this.item == null) return []
        if(this.item.items.length < 3) return this.item.items
        return this.item.items.slice(0, 3)
      },
      orderStatus(){
        if(this.item == null) return ''
        return this.item.status
      }
    },
    methods: {
      viewDetailClick(){
        window.location.href = '/order/' + this.item.ID
      },
      confirmDeliverClick(){
        this.loading = true
        orderApi.confirmDeliver(this.token, this.item.ID).then(()=>{
          this.$emit('onStatusChanged', 'delivered')
          this.loading = false
        }).catch(()=>{
          this.loading = false
        })
      }
    }
}
</script>

<style>

</style>